import { Route } from 'react-router-dom';
import ProtectedRoutes from '../layout/protectedRoutes';
import DashboardPage from '../../pages/dashboard';
import ListAdminProducts from '../../pages/listProductsAdmin';
import UpdateProductPage from '../../pages/updateProduct';
import ListAdminOrders from '../../pages/listOrdersAdmin';
import ProcessOrderPage from '../../pages/processOrder';
import ListAdminUsers from '../../pages/listUsersAdmin';
import UpdateUsersPage from '../../pages/updateUser';
import ProductReviewsPage from '../../pages/productReviews';

const AdminRoutes = () => {
	return (
		<>
			<Route
				path="/admin/dashboard"
				element={
					<ProtectedRoutes admin={true}>
						<DashboardPage />
					</ProtectedRoutes>
				}
			/>
			<Route
				path="/admin/products"
				element={
					<ProtectedRoutes admin={true}>
						<ListAdminProducts />
					</ProtectedRoutes>
				}
			/>
			<Route
				path="/admin/products/:id"
				element={
					<ProtectedRoutes admin={true}>
						<UpdateProductPage />
					</ProtectedRoutes>
				}
			/>

			<Route
				path="/admin/orders"
				element={
					<ProtectedRoutes admin={true}>
						<ListAdminOrders />
					</ProtectedRoutes>
				}
			/>
			<Route
				path="/admin/orders/:id"
				element={
					<ProtectedRoutes admin={true}>
						<ProcessOrderPage />
					</ProtectedRoutes>
				}
			/>
			<Route
				path="/admin/users"
				element={
					<ProtectedRoutes admin={true}>
						<ListAdminUsers />
					</ProtectedRoutes>
				}
			/>
			<Route
				path="/admin/users/:id"
				element={
					<ProtectedRoutes admin={true}>
						<UpdateUsersPage />
					</ProtectedRoutes>
				}
			/>
			<Route
				path="/admin/reviews"
				element={
					<ProtectedRoutes admin={true}>
						<ProductReviewsPage />
					</ProtectedRoutes>
				}
			/>
		</>
	);
};

export default AdminRoutes;
