import { useEffect } from 'react';
import { useGetMyOrdersQuery } from '../redux/api/orderApi';
import toast from 'react-hot-toast';
import Loader from '../components/layout/loader';
import { MDBDataTable } from 'mdbreact';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import MetaData from '../components/layout/metaData';
import { useDispatch } from 'react-redux';
import { clearCart } from '../redux/features/cartSlice';

const MyOrdersPage = () => {
	const { data, isLoading, error } = useGetMyOrdersQuery();
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const orderSuccess = searchParams.get('order_success');

	useEffect(() => {
		if (error) {
			toast.error(error?.data?.message);
		}
		if (orderSuccess) {
			dispatch(clearCart());
			navigate('/me/orders');
		}
	}, [error, orderSuccess, dispatch, navigate]);

	const setOrders = () => {
		const orders = {
			columns: [
				{
					label: 'ID',
					field: 'id',
					sort: 'desc',
				},
				{
					label: 'Amount Paid',
					field: 'amount',
					sort: 'desc',
				},
				{
					label: 'Payment Status',
					field: 'status',
					sort: 'desc',
				},
				{
					label: 'Order Status',
					field: 'orderStatus',
					sort: 'desc',
				},
				{
					label: 'Actions',
					field: 'actions',
					sort: 'desc',
				},
			],
			rows: [],
		};
		data?.orders?.forEach((order) => {
			orders.rows.push({
				id: order?._id,
				amount: `$${order?.totalAmount}`,
				status: order?.paymentInfo?.status?.toUpperCase(),
				orderStatus: order?.orderStatus,
				actions: (
					<>
						<Link to={`/me/order/${order._id}`} className="btn btn-primary">
							<i className="fa fa-eye"></i>
						</Link>
						<Link
							to={`/invoice/order/${order._id}`}
							className="btn btn-success ms-2"
						>
							<i className="fa fa-print"></i>
						</Link>
					</>
				),
			});
		});

		return orders;
	};

	if (isLoading) return <Loader />;

	return (
		<>
			<MetaData title={'My Orders'} />
			<div>
				<h1 className="my-5">{data?.orders?.length} Orders</h1>
				<MDBDataTable
					data={setOrders()}
					className="px-3"
					bordered
					striped
					hover
				/>
			</div>
		</>
	);
};

export default MyOrdersPage;
