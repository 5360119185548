const Footer = () => {
	return (
		<footer className="py-1 pt-5">
			<p className="text-center mt-1 fw-bold">
				ShopIT - QAcart 2023, All Rights Reserved
			</p>
		</footer>
	);
};

export default Footer;
