import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import useUserRoutes from './components/routes/userRoutes';
import useAdminRoutes from './components/routes/adminRoutes';

import Footer from './components/layout/footer';
import Header from './components/layout/header';
import NotFound from './components/layout/notFound';

function App() {
	const userRoutes = useUserRoutes();
	const adminRoutes = useAdminRoutes();
	return (
		<Router>
			<div className="App">
				<Toaster position="bottom-left" />
				<Header />
				<div className="container">
					<Routes>
						{userRoutes}
						{adminRoutes}
						<Route path="*" element={<NotFound />} />
					</Routes>
				</div>
				<Footer />
			</div>
		</Router>
	);
}

export default App;
