import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pagination from 'react-js-pagination';

const CustomPagination = ({ resPerPage, count }) => {
	const [currentPage, setCurrentPage] = useState();
	let [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const page = Number(searchParams.get('page')) || 1;

	useEffect(() => {
		setCurrentPage(page);
	}, [page]);

	const setCurrentPageNo = (pageNum) => {
		setCurrentPage(pageNum);

		if (searchParams.has('page')) {
			searchParams.set('page', pageNum);
		} else {
			searchParams.append('page', pageNum);
		}
		const path = window.location.pathname + '?' + searchParams.toString();
		navigate(path);
	};

	return (
		<div className="d-flex justify-content-center my-4">
			{count > resPerPage && (
				<Pagination
					activePage={currentPage}
					itemsCountPerPage={resPerPage}
					totalItemsCount={count}
					onChange={setCurrentPageNo}
					nextPageText={'Next'}
					prevPageText={'Prev'}
					firstPageText={'First'}
					lastPageText={'Last'}
					itemClass="page-item"
					linkClass="page-link"
				/>
			)}
		</div>
	);
};

export default CustomPagination;
