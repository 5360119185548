import { Route } from 'react-router-dom';
import HomePage from '../../pages/home';
import ProductDetailsPage from '../../pages/productDetails';
import LoginPage from '../../pages/login';
import RegisterPage from '../../pages/register';
import CartPage from '../../pages/cart';
import ProtectedRoutes from '../layout/protectedRoutes';
import ShippingAddressPage from '../../pages/shipping';
import ConfirmOrderPage from '../../pages/confirmOrder';
import PaymentMethodPage from '../../pages/paymentMethod';
import ProfilePage from '../../pages/profile';
import UpdateProfile from '../../pages/updateProfile';
import UpdatePasswordPage from '../../pages/updatePassword';
import ResetPasswordPage from '../../pages/resetPassword';
import MyOrdersPage from '../../pages/myOrders';
import OrderDetailsPage from '../../pages/orderDetails';
import InvoicePage from '../../pages/invoice';

const UserRoutes = () => {
	return (
		<>
			<Route path="/" element={<HomePage />} />
			<Route path="/products/:id" element={<ProductDetailsPage />} />
			<Route path="/login" element={<LoginPage />} />
			<Route path="/register" element={<RegisterPage />} />
			<Route path="/cart" element={<CartPage />} />

			<Route
				path="/shipping"
				element={
					<ProtectedRoutes>
						<ShippingAddressPage />
					</ProtectedRoutes>
				}
			/>
			<Route
				path="/confirm_order"
				element={
					<ProtectedRoutes>
						<ConfirmOrderPage />
					</ProtectedRoutes>
				}
			/>
			<Route
				path="/payment_method"
				element={
					<ProtectedRoutes>
						<PaymentMethodPage />
					</ProtectedRoutes>
				}
			/>
			<Route
				path="/me/profile"
				element={
					<ProtectedRoutes>
						<ProfilePage />
					</ProtectedRoutes>
				}
			/>
			<Route
				path="/me/update_profile"
				element={
					<ProtectedRoutes>
						<UpdateProfile />
					</ProtectedRoutes>
				}
			/>
			<Route
				path="/me/update_password"
				element={
					<ProtectedRoutes>
						<UpdatePasswordPage />
					</ProtectedRoutes>
				}
			/>
			<Route path="/password/reset/:token" element={<ResetPasswordPage />} />
			<Route
				path="/me/orders"
				element={
					<ProtectedRoutes>
						<MyOrdersPage />
					</ProtectedRoutes>
				}
			/>
			<Route
				path="/me/order/:id"
				element={
					<ProtectedRoutes>
						<OrderDetailsPage />
					</ProtectedRoutes>
				}
			/>
			<Route
				path="/invoice/order/:id"
				element={
					<ProtectedRoutes>
						<InvoicePage />
					</ProtectedRoutes>
				}
			/>
		</>
	);
};

export default UserRoutes;
