import { useEffect, useState } from 'react';
import CheckoutSteps from '../components/layout/checkoutSteps';
import MetaData from '../components/layout/metaData';
import { useSelector } from 'react-redux';
import { calculateOrderCost } from '../helpers/helpers';
import {
	useCreateNewOrderMutation,
} from '../redux/api/orderApi';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const PaymentMethodPage = () => {
	const [method, setMethod] = useState();
	const { shippingInfo, cartItems } = useSelector((state) => state.cart);
	const navigate = useNavigate();
	const [createNewOrder, { isLoading, error, isSuccess }] =
		useCreateNewOrderMutation();

	useEffect(() => {
		if (error) {
			toast.error(error?.data?.message);
		}
		if (isSuccess) {
			navigate('/me/orders?order_success=true');
		}
	}, [error, isSuccess, navigate]);

	const submitHandler = (e) => {
		e.preventDefault();
		const { itemsPrice, shippingPrice, taxPrice, totalPrice } =
			calculateOrderCost(cartItems);

		if (method === 'COD') {
			// Create COD order
			const orderData = {
				shippingInfo,
				orderItems: cartItems,
				itemsPrice,
				shippingAmount: shippingPrice,
				taxAmount: taxPrice,
				totalAmount: totalPrice,
				paymentInfo: {
					status: 'Not Paid',
				},
				paymentMethod: 'COD',
			};

			createNewOrder(orderData);
		}
	};

	return (
		<>
			<MetaData title={'Payment method'} />
			<CheckoutSteps shipping confirmOrder payment />
			<div className="row wrapper">
				<div className="col-10 col-lg-5">
					<form className="shadow rounded bg-body" onSubmit={submitHandler}>
						<h2 className="mb-4">Select Payment Method</h2>

						<div className="form-check">
							<input
								className="form-check-input"
								type="radio"
								name="payment_mode"
								id="codradio"
								data-test-id="cash"
								value="COD"
								onChange={(e) => setMethod('COD')}
							/>
							<label className="form-check-label" htmlFor="codradio">
								Cash on Delivery
							</label>
						</div>
						<button
							id="shipping_btn"
							type="submit"
							className="btn py-2 w-100"
							data-test-id="order-btn"
							disabled={isLoading || !method}
						>
							{isLoading ? 'Ordering...' : 'Order'}
						</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default PaymentMethodPage;
