export const getPriceQueryParams = (searchParams, key, value) => {
	const hasValueInParams = searchParams.has(key);

	if (value && hasValueInParams) {
		searchParams.set(key, value);
	} else if (value) {
		searchParams.append(key, value);
	} else if (hasValueInParams) {
		searchParams.delete(key);
	}

	return searchParams;
};

export const calculateOrderCost = (cartItems) => {
	const itemsPrice = cartItems.reduce(
		(acc, item) => acc + item.price * item.quantity,
		0
	);

	const taxPrice = itemsPrice * 0.21;
	const shippingPrice = itemsPrice > 200 ? 0 : 25;

	const totalPrice = itemsPrice + shippingPrice + taxPrice;

	return {
		itemsPrice: Number(itemsPrice).toFixed(2),
		shippingPrice,
		taxPrice: Number(taxPrice).toFixed(2),
		totalPrice: Number(totalPrice).toFixed(2),
	};
};
