import { useEffect, useState } from 'react';
import { useGetProductDetailsQuery } from '../redux/api/productsApi';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import Loader from '../components/layout/loader';
import StarRatings from 'react-star-ratings';
import { useDispatch, useSelector } from 'react-redux';
import { setCartItem } from '../redux/features/cartSlice';
import MetaData from '../components/layout/metaData';
import NewReview from '../components/layout/newReview';
import ReviewList from '../components/layout/listReview';
import NotFound from '../components/layout/notFound';

const ProductDetailsPage = () => {
	const params = useParams();
	const dispatch = useDispatch();

	const [quantity, setQuantity] = useState(1);
	const [activeImage, setActiveImage] = useState('');

	const { data, isLoading, error, isError } = useGetProductDetailsQuery(
		params?.id
	);
	const product = data?.product;
	const { isAuthenticated } = useSelector((state) => state.auth);

	useEffect(() => {
		setActiveImage(
			product?.images[0]
				? product?.images[0].url
				: '/images/default_product.png'
		);
	}, [product]);

	useEffect(() => {
		if (isError) {
			toast.error(error.data.message);
		}
	}, [isError, error]);

	const increaseQuantity = () => {
		const count = document.querySelector('.count');
		if (count.valueAsNumber >= product?.stock) return;

		const qty = count.valueAsNumber + 1;
		setQuantity(qty);
	};

	const decreaseQuantity = () => {
		const count = document.querySelector('.count');
		if (count.valueAsNumber <= 1) return;

		const qty = count.valueAsNumber - 1;
		setQuantity(qty);
	};

	const setItemsToCart = () => {
		const cartItem = {
			product: product?._id,
			name: product?.name,
			price: product?.price,
			image: product?.images[0]?.url,
			stock: product?.stock,
			quantity,
		};
		dispatch(setCartItem(cartItem));
		toast.success('Item added to cart');
	};

	if (isLoading) return <Loader />;

	if (error && error?.status === 404) {
		return <NotFound />;
	}

	return (
		<>
			<MetaData title={product?.name} />
			<div className="row d-flex justify-content-around">
				<div className="col-12 col-lg-5 img-fluid" id="product_image">
					<div className="p-3">
						<img
							className="d-block w-100"
							src={activeImage}
							alt={product?.name}
							width="340"
							height="390"
						/>
					</div>
					<div className="row justify-content-start mt-5">
						{product?.images.map((image) => (
							<div className="col-2 ms-4 mt-2">
								<div role="button">
									<img
										className={`d-block border rounded p-3 cursor-pointer ${
											image.url === activeImage ? 'border-warning' : ''
										}`}
										height="100"
										width="100"
										src={image?.url}
										alt={product?.name}
										onClick={() => setActiveImage(image.url)}
									/>
								</div>
							</div>
						))}
					</div>
				</div>

				<div className="col-12 col-lg-5 mt-5">
					<h3 data-test-id="product-name">{product?.name}</h3>
					<p id="product_id">Product # {product?._id}</p>

					<hr />

					<div className="d-flex">
						<StarRatings
							rating={product?.ratings}
							starRatedColor="#ffb829"
							numberOfStars={5}
							name="rating"
							starDimension="24px"
							starSpacing="1px"
						/>
						<span id="no-of-reviews" className="pt-1 ps-2">
							{' '}
							({product?.numOfReviews} Reviews){' '}
						</span>
					</div>
					<hr />

					<p data-test-id="product-price" id="product_price">€{product?.price}</p>
					<div className="stockCounter d-inline">
						<span className="btn btn-danger minus" onClick={decreaseQuantity} data-test-id="minus">
							-
						</span>
						<input
							type="number"
							className="form-control count d-inline"
							value={quantity}
							readonly
						/>
						<span className="btn btn-primary plus" onClick={increaseQuantity} data-test-id="plus">
							+
						</span>
					</div>
					<button
						type="button"
						id="cart_btn"
						className="btn btn-primary d-inline ms-4"
						disabled={product?.stock <= 0}
						onClick={setItemsToCart}
						data-test-id="add-to-cart-button"
					>
						Add to Cart
					</button>

					<hr />

					<p>
						Status:{' '}
						<span
							id="stock_status"
							data-test-id="stock_status"
							className={product?.stock > 0 ? 'greenColor' : 'redColor'}
						>
							{product?.stock > 0 ? 'In Stock' : 'Out of Stock'}
						</span>
					</p>

					<hr />

					<h4 className="mt-2">Description:</h4>
					<p data-test-id="product-description">{product?.description}</p>
					<hr />
					<p id="product_seller mb-3" data-test-id="product-seller">
						Sold by: <strong>{product?.seller}</strong>
					</p>

					{isAuthenticated ? (
						<NewReview productId={product?._id} />
					) : (
						<div className="alert alert-danger my-5" type="alert" data-test-id="login-to-review">
							Login to post your review.
						</div>
					)}
				</div>
			</div>
			{product?.reviews?.length > 0 && (
				<ReviewList reviews={product?.reviews} />
			)}
		</>
	);
};

export default ProductDetailsPage;
