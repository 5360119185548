import { useEffect } from 'react';
import toast from 'react-hot-toast';
import Loader from '../components/layout/loader';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import MetaData from '../components/layout/metaData';

import AdminLayout from '../components/layout/adminLayout';
import {
	useDeleteUserMutation,
	useGetAdminUsersQuery,
} from '../redux/api/userApi';

const ListAdminUsers = () => {
	const { data, isLoading, error } = useGetAdminUsersQuery();

	const [
		deleteUser,
		{ error: deleteUserError, isSuccess, isLoading: isDeleteUserLoading },
	] = useDeleteUserMutation();

	useEffect(() => {
		if (error) {
			toast.error(error?.data?.message);
		}
		if (deleteUserError) {
			toast.error(deleteUserError?.data?.message);
		}
		if (isSuccess) {
			toast.success('User Deleted');
		}
	}, [error, deleteUserError, isSuccess]);

	const deleteUserHandler = (id) => {
		deleteUser(id);
	};

	const setUsers = () => {
		const users = {
			columns: [
				{
					label: 'ID',
					field: 'id',
					sort: 'asc',
				},
				{
					label: 'Name',
					field: 'name',
					sort: 'asc',
				},
				{
					label: 'Email',
					field: 'email',
					sort: 'asc',
				},
				{
					label: 'Role',
					field: 'role',
					sort: 'asc',
				},
				{
					label: 'Actions',
					field: 'actions',
					sort: 'asc',
				},
			],
			rows: [],
		};
		data?.users?.forEach((user) => {
			users.rows.push({
				id: user?._id,
				name: user?.name,
				email: user?.email,
				role: user?.role,
				actions: (
					<>
						<Link
							to={`/admin/users/${user._id}`}
							className="btn btn-outline-primary"
						>
							<i className="fa fa-pencil"></i>
						</Link>

						<button
							className="btn btn-outline-danger ms-2"
							onClick={() => deleteUserHandler(user?._id)}
							disabled={isDeleteUserLoading}
						>
							<i className="fa fa-trash"></i>
						</button>
					</>
				),
			});
		});

		return users;
	};

	if (isLoading) return <Loader />;

	return (
		<AdminLayout>
			<MetaData title={'All Users'} />
			<div>
				<h1 className="my-5">{data?.users?.length} Users</h1>
				<MDBDataTable
					data={setUsers()}
					className="px-3"
					bordered
					striped
					hover
				/>
			</div>
		</AdminLayout>
	);
};

export default ListAdminUsers;
