import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../redux/api/authApi';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import MetaData from '../components/layout/metaData';

const LoginPage = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [login, { isLoading, error }] = useLoginMutation();
	const { isAuthenticated } = useSelector((state) => state.auth);
	const navigate = useNavigate();

	useEffect(() => {
		if (isAuthenticated) {
			navigate('/');
		}
		if (error) {
			toast.error(error?.data?.message);
		}
	}, [error, isAuthenticated, navigate]);

	const submitHandler = async (e) => {
		e.preventDefault();
		const loginData = {
			email,
			password,
		};

		await login(loginData);
	};

	return (
		<>
			<MetaData title={'Login Page'} />
			<div className="row wrapper">
				<div className="col-10 col-lg-5">
					<form className="shadow rounded bg-body" onSubmit={submitHandler}>
						<h2 className="mb-4">Login</h2>
						<div className="mb-3">
							<label htmlFor="email_field" className="form-label">
								Email
							</label>
							<input
								type="email"
								id="email_field"
								className="form-control"
								data-test-id="email-input"
								name="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>

						<div className="mb-3">
							<label htmlFor="password_field" className="form-label">
								Password
							</label>
							<input
								type="password"
								id="password_field"
								className="form-control"
								name="password"
								value={password}
								data-test-id="password-input"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>

						<button
							id="login_button"
							data-test-id="login-button"
							type="submit"
							className="btn w-100 py-2"
							disabled={isLoading}
						>
							{isLoading ? 'Loading...' : 'LOGIN'}
						</button>

						<div className="my-3">
							<Link to="/register" className="float-end">
								New User?
							</Link>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default LoginPage;
