import { useEffect } from 'react';
import toast from 'react-hot-toast';
import Loader from '../components/layout/loader';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import MetaData from '../components/layout/metaData';

import AdminLayout from '../components/layout/adminLayout';
import {
	useDeleteOrderMutation,
	useGetAdminOrdersQuery,
} from '../redux/api/orderApi';

const ListAdminOrders = () => {
	const { data, isLoading, error } = useGetAdminOrdersQuery();

	const [
		deleteOrder,
		{ error: deleteOrderError, isSuccess, isLoading: isDeleteOrderLoading },
	] = useDeleteOrderMutation();

	useEffect(() => {
		if (error) {
			toast.error(error?.data?.message);
		}
		if (deleteOrderError) {
			toast.error(deleteOrderError?.data?.message);
		}
		if (isSuccess) {
			toast.success('Order Deleted');
		}
	}, [error, deleteOrderError, isSuccess]);

	const deleteOrderHandler = (id) => {
		deleteOrder(id);
	};

	const setOrders = () => {
		const orders = {
			columns: [
				{
					label: 'ID',
					field: 'id',
					sort: 'asc',
				},
				{
					label: 'Payment Status',
					field: 'paymentStatus',
					sort: 'asc',
				},
				{
					label: 'Order Status',
					field: 'orderStatus',
					sort: 'asc',
				},
				{
					label: 'Actions',
					field: 'actions',
					sort: 'asc',
				},
			],
			rows: [],
		};
		data?.orders?.forEach((order) => {
			orders.rows.push({
				id: order?._id,
				paymentStatus: order?.paymentInfo?.status.toUpperCase(),
				orderStatus: order?.orderStatus,
				actions: (
					<>
						<Link
							to={`/admin/orders/${order._id}`}
							className="btn btn-outline-primary"
						>
							<i className="fa fa-pencil"></i>
						</Link>

						<button
							className="btn btn-outline-danger ms-2"
							onClick={() => deleteOrderHandler(order?._id)}
							disabled={isDeleteOrderLoading}
						>
							<i className="fa fa-trash"></i>
						</button>
					</>
				),
			});
		});

		return orders;
	};

	if (isLoading) return <Loader />;

	return (
		<AdminLayout>
			<MetaData title={'All Orders Admin'} />
			<div>
				<h1 className="my-5">{data?.orders?.length} Orders</h1>
				<MDBDataTable
					data={setOrders()}
					className="px-3"
					bordered
					striped
					hover
				/>
			</div>
		</AdminLayout>
	);
};

export default ListAdminOrders;
