import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const productApi = createApi({
	reducerPath: 'productApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `/api/v1`,
	}),
	tagTypes: ['Product', 'AdminProducts', 'Reviews'],
	endpoints: (builder) => ({
		getProducts: builder.query({
			query: (params) => ({
				url: '/products',
				params: {
					page: params?.page,
					keyword: params?.keyword,
					category: params?.category,
					'price[gte]': params.min,
					'price[lte]': params.max,
					'ratings[gte]': params.ratings,
				},
			}),
		}),
		getProductDetails: builder.query({
			query: (id) => `/products/${id}`,
			providesTags: ['Product'],
		}),
		submitReview: builder.mutation({
			query(body) {
				return {
					url: '/reviews',
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: ['Product'],
		}),
		canReview: builder.query({
			query: (productId) => `/can_review/?productId=${productId}`,
		}),
		getAdminProducts: builder.query({
			query: () => `/admin/products`,
			providesTags: ['AdminProducts'],
		}),
		updateProduct: builder.mutation({
			query({ id, body }) {
				return {
					url: `/admin/products/${id}`,
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: ['AdminProducts', 'Product'],
		}),

		getProductReviews: builder.query({
			query: (productId) => `/reviews/?id=${productId}`,
			providesTags: ['Reviews'],
		}),
		deleteReview: builder.mutation({
			query({ productId, id }) {
				return {
					url: `/admin/reviews/?productId=${productId}&id=${id}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['Reviews'],
		}),
	}),
});

export const {
	useGetProductsQuery,
	useGetProductDetailsQuery,
	useSubmitReviewMutation,
	useCanReviewQuery,
	useGetAdminProductsQuery,
	useUpdateProductMutation,
	useLazyGetProductReviewsQuery,
	useDeleteReviewMutation,
} = productApi;
