import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const SideBarMenu = ({ menuItems }) => {
	const location = useLocation();
	const [activeMenuItem, setActiveMenuItem] = useState(location.pathname);

	const handleMenuItemClick = (menuItemUrl) => {
		setActiveMenuItem(menuItemUrl);
	};

	return (
		<div className="list-group mt-5 pl-4">
			{menuItems.map((item) => (
				<Link
					key={item.url}
					to={item.url}
					className={`fw-bold list-group-item list-group-item-action sidebar-menu-item ${
						activeMenuItem.includes(item.url) ? 'active' : ''
					}`}
					onClick={() => handleMenuItemClick(item.url)}
					aria-current={activeMenuItem.includes(item.url) ? 'true' : 'false'}
					data-test-id={`sidebar-menu-item-${item.name}`}
					id={`sidebar-menu-item-${item.name}`}
				>
					<i className={`${item.icon} fa-fw pe-2`}></i> {item.name}
				</Link>
			))}
		</div>
	);
};

export default SideBarMenu;
