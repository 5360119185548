import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateUProfileMutation } from '../redux/api/userApi';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import UserLayout from '../components/layout/userLayout';
import MetaData from '../components/layout/metaData';

const UpdateProfile = () => {
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');

	const navigate = useNavigate();

	const [updateProfile, { isLoading, error, isSuccess }] =
		useUpdateUProfileMutation();

	const { user } = useSelector((state) => state.auth);

	useEffect(() => {
		if (user) {
			setName(user.name);
			setEmail(user.email);
		}

		if (error) {
			toast.error(error?.data?.message);
		}
		if (isSuccess) {
			toast.success('User Updated');
			navigate('/me/profile');
		}
	}, [user, error, isSuccess, navigate]);

	const submitHandler = (e) => {
		e.preventDefault();
		const userData = {
			email,
			name,
		};

		updateProfile(userData);
	};

	return (
		<UserLayout>
			<MetaData title={'Update Profile Page'} />
			<div className="row wrapper">
				<div className="col-10 col-lg-8">
					<form className="shadow rounded bg-body" onSubmit={submitHandler}>
						<h2 className="mb-4">Update Profile</h2>

						<div className="mb-3">
							<label htmlFor="name_field" className="form-label">
								{' '}
								Name{' '}
							</label>
							<input
								type="text"
								id="name_field"
								className="form-control"
								name="name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>

						<div className="mb-3">
							<label htmlFor="email_field" className="form-label">
								{' '}
								Email{' '}
							</label>
							<input
								type="email"
								id="email_field"
								className="form-control"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>

						<button
							type="submit"
							className="btn update-btn w-100"
							disabled={isLoading}
						>
							{isLoading ? 'Updating...' : 'Update'}
						</button>
					</form>
				</div>
			</div>
		</UserLayout>
	);
};

export default UpdateProfile;
