import { Link, useNavigate } from 'react-router-dom';
import MetaData from '../components/layout/metaData';
import { useDispatch, useSelector } from 'react-redux';
import { setCartItem, removeCartItem } from '../redux/features/cartSlice';

const CartPage = () => {
	const { cartItems } = useSelector((state) => state.cart);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const increaseQuantity = (item, quantity) => {
		const newQuantity = quantity + 1;
		if (newQuantity > item?.stock) return;
		setItemsToCart(item, newQuantity);
	};

	const decreaseQuantity = (item, quantity) => {
		const newQuantity = quantity - 1;
		if (newQuantity <= 0) return;
		setItemsToCart(item, newQuantity);
	};

	const setItemsToCart = (item, newQuantity) => {
		const cartItem = {
			product: item?.product,
			name: item?.name,
			price: item?.price,
			image: item?.image,
			stock: item?.stock,
			quantity: newQuantity,
		};
		dispatch(setCartItem(cartItem));
	};

	const removeItemFromCartHandler = (id) => {
		dispatch(removeCartItem(id));
	};

	const checkoutHandler = () => {
		navigate('/shipping');
	};

	return (
		<>
			<MetaData title={'Your Cart'} />
			{cartItems.length === 0 ? (
				<h2 className="mt-5">Your Cart is Empty</h2>
			) : (
				<>
					<h2 className="mt-5" data-test-id="cart-title">
						Your Cart: <b>{cartItems.length} items</b>
					</h2>
					<div className="row d-flex justify-content-between">
						<div className="col-12 col-lg-8">
							{cartItems.map((item) => (
								<>
									<hr />
									<div className="cart-item" data-key="product1" data-test-id="cart-item">
										<div className="row">
											<div className="col-4 col-lg-3">
												<img
													src={item?.image}
													alt="Laptop"
													height="90"
													width="115"
												/>
											</div>
											<div className="col-5 col-lg-3">
												<Link to={`/products/${item?.product}`} data-test-id="item-name">
													{' '}
													{item?.name}{' '}
												</Link>
											</div>
											<div className="col-4 col-lg-2 mt-4 mt-lg-0">
												<p data-test-id="item-price" id="card_item_price">€{item?.price}</p>
											</div>
											<div className="col-4 col-lg-3 mt-4 mt-lg-0">
												<div className="stockCounter d-inline">
													<span
														className="btn btn-danger minus"
														onClick={() =>
															decreaseQuantity(item, item.quantity)
														}
													>
														{' '}
														-{' '}
													</span>
													<input
														type="number"
														className="form-control count d-inline"
														data-test-id="item-quantity"
														value={item.quantity}
														readonly
													/>
													<span
														className="btn btn-primary plus"
														onClick={() =>
															increaseQuantity(item, item.quantity)
														}
													>
														{' '}
														+{' '}
													</span>
												</div>
											</div>
											<div className="col-4 col-lg-1 mt-4 mt-lg-0">
												<i
													id="delete_cart_item"
													className="fa fa-trash btn btn-danger"
													onClick={() =>
														removeItemFromCartHandler(item.product)
													}
												></i>
											</div>
										</div>
									</div>
									<hr />
								</>
							))}
						</div>

						<div className="col-12 col-lg-3 my-4">
							<div id="order_summary">
								<h4>Order Summary</h4>
								<hr />
								<p>
									Unites:{' '}
									<span className="order-summary-values" data-test-id="summary-quantity">
										{cartItems.reduce((acc, item) => acc + item?.quantity, 0)}{' '}
										(Units)
									</span>
								</p>
								<p>
									Est. total:{' '}
									<span className="order-summary-values" data-test-id="summary-estimated-total">
										€
										{cartItems
											.reduce(
												(acc, item) => acc + item?.quantity * item?.price,
												0
											)
											.toFixed(2)}{' '}
									</span>
								</p>
								<hr />
								<button
									data-test-id="checkout-button"
									id="checkout_btn"
									className="btn btn-primary w-100"
									onClick={checkoutHandler}
								>
									Check out
								</button>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default CartPage;
