import { useEffect } from 'react';
import Loader from '../components/layout/loader';
import MetaData from '../components/layout/metaData';
import ProductItem from '../components/layout/productItem';
import { useGetProductsQuery } from '../redux/api/productsApi';
import toast from 'react-hot-toast';
import CustomPagination from '../components/layout/customPagination';
import { useSearchParams } from 'react-router-dom';
import Filter from '../components/layout/filter';

const HomePage = () => {
	let [searchParams] = useSearchParams();
	const page = searchParams.get('page') || 1;
	const keyword = searchParams.get('keyword') || '';
	const min = searchParams.get('min');
	const max = searchParams.get('max');
	const category = searchParams.get('category');
	const ratings = searchParams.get('ratings');

	const params = { page, keyword };
	min !== null && (params.min = min);
	max !== null && (params.max = max);
	category !== null && (params.category = category);
	ratings !== null && (params.ratings = ratings);

	const { data, isLoading, error, isError } = useGetProductsQuery(params);

	useEffect(() => {
		if (isError) {
			toast.error(error.data.message);
		}
	}, [isError, error]);

	const columnSize = keyword ? 4 : 3;

	if (isLoading) return <Loader />;

	return (
		<>
			<MetaData title={'Buy best product online'} />
			<div className="row">
				{keyword && (
					<div className="col-6 col-md-3 mt-5">
						<Filter />
					</div>
				)}
				<div
					className={keyword ? 'col-6 col-md-9 w-100' : 'col-6 col-md-12 w-100'}
				>
					<h1 id="products_heading" data-test-id="product-heading" className="text-secondary">
						{keyword
							? `${data?.products?.length} Products found with keyword: ${keyword} `
							: 'Latest Products'}
					</h1>

					<section id="products" className="mt-5">
						<div className="row">
							{data?.products.map((product) => (
								<ProductItem
									key={product._id}
									product={product}
									columnSize={columnSize}
								/>
							))}
						</div>
					</section>
					<CustomPagination resPerPage={data?.resPerPage} count={data?.count} />
				</div>
			</div>
		</>
	);
};

export default HomePage;
